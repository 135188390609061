import React from "react"
import cocktail from './assets/cocktail.svg'

function Home() {

    return (
        <img src={cocktail} alt="Cocktail" width="100%" height="auto" /> 
    )
}

export default Home;

